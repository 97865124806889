import React, { Component } from 'react';

class Adsense extends Component {
  componentDidMount() {
    if(window) (window.adsbygoogle = window.adsbygoogle || []).push({});
  };

  render() {
    return (
      <ins className={`${this.props.className} adsbygoogle`}
        style={this.props.style}
        data-ad-client={this.props.client}
        data-ad-slot={this.props.slot}
        data-ad-layout={this.props.layout}
        data-ad-layout-key={this.props.layoutKey}
        data-ad-format={this.props.format}
        data-full-width-responsive={this.props.responsive}></ins>
    );
  }
};

export default Adsense;
